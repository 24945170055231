import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/index.css";
import routes from 'routes/routes';
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import ScrollToTop from './scrollToTop';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop>
      <IndexNavbar />
      <div className="jobacademy-content">
      <Switch>
        {routes.map((rVal, rKey) => (
          <Route key={`route${rKey}`} exact path={rVal.path}>
            {rVal.component}
          </Route>
        ))}
      </Switch>
      </div>
      <Footer />
    </ScrollToTop>
  </BrowserRouter>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
