import React from 'react';
import { Helmet } from 'react-helmet';
export default function PrivacyPolicy() {
  return (
    <div className="container mx-auto lg:py-10">
      <div className="flex flex-wrap items-center">
        <div className="w-full lg:w-8/12 mx-auto px-4 leading-relaxed">
          <Helmet >
            <title>Jobacademy - Job ready platform</title>
            <meta
              name="description"
              content="Jobacademy Job Ready platform helps job seekers achieve their job goal by connecting them with real time experienced professionals (Job Gurus) for mentorship, learning and skill verification."
            />
            <meta property="og:title" content="Jobacademy - Job ready platform" />
            <meta property="og:url" content="https://jobacademy.ai" />
            <meta property="og:image" itemprop="image" content="https://jobacademy.ai/jobacademy-social.png" />
          </Helmet>
          <div className="text-justify">
            <h3 className="text-2xl font-semibold py-3 mb-6">Frequently asked questions(FAQs)</h3>
            <h3 className="text-xl font-semibold py-3">1. What if the microphone is not allowing access to the classroom?</h3>
            <ul className="faq-list">
              <li>
                Go to browser settings or website preferences.
              </li>
              <li>
                Go to microphone and enable it.
              </li>
            </ul>
          </div>
          <div className="text-justify">
            <h3 className="text-xl font-semibold py-3">2. How to avoid disturbance in a classroom and maintain voice clarity?</h3>
            <ul className="faq-list">
              <li>
                We recommend the use of good quality headsets with a microphone in order to have good voice clarity with no disturbance.
              </li>
            </ul>
          </div>
          <div className="text-justify">
            <h3 className="text-xl font-semibold py-3">
              3. What is the suggested internet speed?
            </h3>
            <ul className="faq-list">
              <li>
                To have a good voice clarity and to avoid noise, the minimum download speed of your internet should be 2mbps.
              </li>
            </ul>
          </div>
          <div className="text-justify">
            <h3 className="text-xl font-semibold py-3">
            4. What are the recommended browsers for the different OS you use?
            </h3>
            <ul className="faq-list">
              <li>
              For Windows :- Google chrome, Microsoft edge.
              </li>
              <li>
              For Linux :- Google chrome, Mozilla firefox.
              </li>
              <li>
              For Mac OS :- Apple Safari.
              </li>
            </ul>
          </div>
          <div className="text-justify">
            <h3 className="text-xl font-semibold py-3">
            5. What if the camera is not allowing to access to video?
            </h3>
            <ul className="faq-list">
              <li>
              Go to browser settings and allow camera access.
              </li>
              <li>
              For good quality camera, change the definition to high or medium depending upon the speed of your internet.
              </li>
            </ul>
          </div>
          <div className="text-justify">
            <h3 className="text-xl font-semibold py-3">
            6. What if you did not receive any activation or forgot password mails?
            </h3>
            <ul className="faq-list">
              <li>
              Primarily, check your Inbox.
              </li>
              <li>
              If you didn’t receive in Inbox yet, check your spam mail.
              </li>
              <li>
              Hardly, if you did not receive any mails yet, kindly mail us at support@jobacademy.ai.
              </li>
            </ul>
          </div>
          <div className="text-justify">
            <h3 className="text-xl font-semibold py-3">
            7. What if I observed other issues in an application?
            </h3>
            <ul className="faq-list">
              <li>
              For any other issues you observe, kindly brief your experiences in feedback section of our application.
              </li>
              <li>
              Take a screenshot of the error you observe and upload it in feedback respectively.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
