
import Goals from './Goals';
import GoalProfile from './GoalProfile';
import Courses from './Courses';
import CoursesProfile from './CourseProfile';
// import Jobs from './Jobs';
// import JobsProfile from './JobProfile';
// import ComingSoon from '../ComingSoon';

export const servicesConfigs = [
    {
        path: '/job-goals',
        component: Goals,
    },
    {
        path: '/job-goals/:id',
        component: GoalProfile,
    },
    // {
    //     path: '/guidance',
    //     component: ComingSoon,
    // },
    // {
    //     path: '/verify-skill',
    //     component: ComingSoon,
    // },
    {
        path: '/courses',
        component: Courses,
    },
    {
        path: '/courses/:id',
        component: Courses,
    },
    {
        path: '/course-profile/:id',
        component: CoursesProfile,
    },
    // {
    //     path: '/jobs',
    //     component: Jobs,
    // },
    // {
    //     path: '/jobs/:id',
    //     component: JobsProfile,
    // },
];
