import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import $ from 'jquery';

export default function Navbar() {
  const history = useHistory();
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [clsName, setClsName] = React.useState('bg-sky-blue');
  useEffect(() => {
    window.addEventListener("scroll", () => {
         if (window.scrollY === 0) {
          setClsName('bg-sky-blue');
         } else {
          setClsName('shadow');
         }
    });
  }, []);
  return (
    <>
      <nav className={`top-0 sticky z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white ${history.location.pathname === '/' ? window.scrollY === 0 ? 'bg-sky-blue' : clsName : 'shadow'}`}>
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              to="/"
              onClick={() => {
                setClsName('bg-sky-blue');
                $('html,body').animate({
                  scrollTop: 0
                }, 1000);
              }}
              className="text-gray-800 text-sm font-bold leading-relaxed inline-block mr-4 whitespace-no-wrap uppercase"
            >
              <img
                src={require("assets/img/brand/jobacademy-logo.png")}
                alt="..."
                width="150"
              />
            </Link>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>
          <div
            className={
              "mb-navbar lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none" +
              (navbarOpen ? " block" : " hidden")
            }
            id="example-navbar-warning"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li
                className="flex items-center hover:text-gray-600 text-gray-800 px-3 py-4 lg:py-2 text-md font-semibold cursor-pointer"
                onClick={() => {
                  const target = $('#guidRef');
                  if (target.length) {
                      $('html,body').animate({
                          scrollTop: target.offset().top
                      }, 1000);
                      setNavbarOpen(false);
                      return false;
                  } else {
                    history.push('/');
                  }
                }}
              >
                  Guidance
              </li>

              <li
                className="flex items-center hover:text-gray-600 text-gray-800 px-3 py-4 lg:py-2 text-md font-semibold cursor-pointer"
                onClick={() => {
                  const target = $('#clsRef');
                  if (target.length) {
                      $('html,body').animate({
                          scrollTop: target.offset().top
                      }, 1000);
                      setNavbarOpen(false);
                      return false;
                  } else {
                    history.push('/');
                  }
                }}
              >
                Courses
              </li>

              <li
                className="flex items-center hover:text-gray-600 text-gray-800 px-3 py-4 lg:py-2 text-md font-semibold cursor-pointer"
                onClick={() => {
                  const target = $('#jobRef');
                  if (target.length) {
                      $('html,body').animate({
                          scrollTop: target.offset().top
                      }, 1000);
                      setNavbarOpen(false);
                      return false;
                  } else {
                    history.push('/');
                  }
                }}
              >
                  Placements
              </li>
              <li
                className="flex items-center hover:text-gray-600 text-gray-800 px-3 py-4 lg:py-2 text-md font-semibold cursor-pointer"
                onClick={() => {
                  const target = $('#tutorRef');
                  if (target.length) {
                      $('html,body').animate({
                          scrollTop: target.offset().top
                      }, 1000);
                      setNavbarOpen(false);
                      return false;
                  } else {
                    history.push('/');
                  }
                }}
              >
                  Become a tutor
              </li>
              <li className="flex items-center">
                <a className="no-underline" href="https://app.jobacademy.ai/">
                  <button
                    className="bg-blue-500 text-white active:bg-blue-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                    type="button"
                  >
                    Login <i className="fas fa-arrow-alt-circle-right"></i>
                  </button>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
