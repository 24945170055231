
import React from 'react';
import AboutUs from './About';
import ContactUs from './Contact';
import PrivacyPolicy from './PrivacyPolicy';
import TermsAndConditions from './TermsAndConditions';
import Faq from './Faq';

export const generalConfigs = [
    {
        path: '/about',
        component: () => <AboutUs />,
    },
    {
        path: '/contact',
        component: () => <ContactUs />,
    },
    {
        path: '/privacy-policy',
        component: () => <PrivacyPolicy />,
    },
    {
        path: '/terms-conditions',
        component: () => <TermsAndConditions />,
    },
    {
        path: '/faq',
        component: <Faq />,
    }
];
