import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import basepath from 'basepath';
import Loader from 'Loader';
import { Grid } from '@material-ui/core';
import axios from 'axios';
import {Helmet} from "react-helmet";
import ComingSoon from 'ComingSoon';

class TrendingClasses extends Component {
  state = {
    trendingCourses: undefined,
    contentLoader: false,
  }; 
  componentDidMount() {
     const _this = this;
     let filters = 'all';
     if (window.location.pathname.split('/')[2]) {
      filters = this.props.location.state.labels;
     }
     axios.get(`${basepath}/trendingclasses/${filters}`)
    .then(function (response) {
      _this.setState({ trendingCourses: response.data.info, contentLoader: true });
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  handleClassProfile = (courseName, courseId) => {
    this.props.history.push({ 
      pathname: `/Services/Courses/${courseName}`,
      state: { courseId },
    });
  }
  render() {
    const { contentLoader, trendingCourses } = this.state;
    return !contentLoader ? (
      <Loader />
    ) : (
      <div className="w-full h-full flex bg-gray-100">
        <Helmet >
          <title>Jobacademy - Job ready platform</title>
          <meta
            name="description"
            content="Jobacademy Job Ready platform helps job seekers achieve their job goal by connecting them with real time experienced professionals (Job Gurus) for mentorship, learning and skill verification."
          />
          <meta property="og:title" content="Jobacademy - Job ready platform" />
          <meta property="og:url" content="https://jobacademy.ai" />
          <meta property="og:image" itemprop="image" content="https://jobacademy.ai/jobacademy-social.png" />
        </Helmet>   
        {trendingCourses !== undefined  &&  trendingCourses.length > 0 ? (
         <Grid container className="p-4 w-full">
          {trendingCourses.map((course, key) => (
          <Grid key={`course-${key}`} item md={3}  xs={12} className="sm:flex flex-col p-2">
            <div className="flex flex-col bg-white w-full shadow rounded-lg">
              <blockquote>
                <div className="video-card-layer">
                  <div className="video-card-layer h-80 overflow-hidden">
                    <img alt="" src={`${basepath}/uploads/videothumbnail/360/${course.videoThumbnail}`} />
                  </div>
                </div>
                <h4 className="text-base font-semibold px-4 mt-2 truncate">{course.className}</h4>
               <p className="text-sm px-4 inline-display-name text-gray-500 mb-4">by <span className="capitalize"> {`${course.mentorData[0].firstName} ${course.mentorData[0].lastName}`} </span></p>
                <div 
                  className="w-full flex justify-center p-2 text-base font-semibold text-blue-400 hover:text-blue-600 cursor-pointer footer-actions"
                  onClick={() => this.props.history.push({
                    pathname: `/course-profile/${course.className}`,
                    state: {
                      courseId: course._id,
                    },
                  })}
                >
                  View course
                </div>
              </blockquote>
            </div>
          </Grid>
        ))}
      </Grid>
        ) : (
          <ComingSoon />
        )
  }
      </div>
    );
  }
}

export default withRouter(TrendingClasses);
