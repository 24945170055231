import React from 'react';
import { Helmet } from 'react-helmet';
import basepath from 'basepath';
import { withRouter } from 'react-router-dom';
function Videoplayer({ src }) {
    return (
      <div tabIndex="-1" className="videoplayer w-full h-230">
        <video className="w-full h-full" controls src={`${basepath}/${src}`} />
      </div>
    );
  }
class Profile extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            vid: true,
        }
    }
    playVid = () => {
        this.setState({ vid: false });
    }
    render(){
        const goalInfo = this.props.location.state.Goals;
        const { vid } = this.state;
        return (
          <div className="container mx-auto">
            <div className="w-full lg:w-11/12 mx-auto p-4 flex items-center mb-6">
                <div className="w-6/12 flex items-center">
                    {/* <span
                        className="arrow-back shadow cursor-pointer"
                        onClick={() => this.props.history.push('/job-goals') }
                    >
                        <i class="fas fa-arrow-left text-gray-500 h-full flex items-center justify-center"></i>
                    </span> */}
                    <h4 className="text-2xl font-bold">{goalInfo.goal}</h4>
                </div>
                <div className="w-6/12 ml-auto flex justify-end">
                    <button
                        className="bg-blue-500 text-white active:bg-blue-600 text-xs font-bold px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                        type="button"
                        onClick = {() => {
                            window.location = `https://app.jobacademy.ai/goalprofile/${goalInfo._id}`;
                        }}
                    >
                        Set this goal
                    </button>
                </div>
            </div>
            <div className="flex flex-wrap items-center">
              <div className="w-full lg:w-8/12 mx-auto px-4 leading-relaxed">
                <Helmet>          
                    <title>{goalInfo.goal}</title>
                    <meta property="og:title" content="TrendingGoals" />
                    <meta property="og:description" content="Trending Goals of Jobacademy" />
                    <meta property="og:image" content="https://jobacademy.ai/jobacademy-social.png" />
                </Helmet>
                {vid ? (
                  <div className="video-card-layer h-230">
                    <div className="video-mock-layer flex justify-center items-center border-rds-7 overflow-hidden">
                      <i class="fas fa-play text-gray-500 cursor-pointer text-3xl" onClick={this.playVid}></i>
                    </div>
                    <img alt=" " src={`${basepath}/uploads/videothumbnail/360/${goalInfo.videoThumbnail}`} className="w-full" />
                  </div>
                ) : (
                  <Videoplayer src={goalInfo.video} className="jobacademy-demo-video w-full" />
                )}
                <div className="text-justify">
                    <div className="pb-6 mt-6 flex">
                        <span dangerouslySetInnerHTML={
                        { __html: goalInfo.description }}
                        />
                    </div>
                    <div className="goal-roles-section">
                        <h3 className="text-lg font-semibold">Roles and responsibilities </h3>
                        <div
                        dangerouslySetInnerHTML={
                            { __html: goalInfo.rolesAndResp }}
                        />
                    </div>
                    <div className="goal-growth-section">
                        <h3 className="text-lg font-semibold mb-4">Learning time</h3>
                        <span
                        className="p-20 text-16 align-justify"
                        dangerouslySetInnerHTML={
                            { __html: goalInfo.learnTime }}
                        />
                    </div>
                    <div className="goal-growth-section">
                        <h3 className="text-lg font-semibold mb-4">Estimated salary</h3>
                        <span
                        className="p-20 text-16 align-justify"
                        dangerouslySetInnerHTML={
                            { __html: goalInfo.estSalaries }}
                        />
                    </div>
                    <div className="goal-growth-section">
                        <h3 className="text-lg font-semibold mb-4">Goal growth</h3>
                        <span
                        className="py-16"
                        dangerouslySetInnerHTML={
                            { __html: goalInfo.goalGrowth }}
                        />
                    </div>
                    <div className="goal-roles-section">
                        <h3 className="text-lg font-semibold mb-4">Required skills</h3>
                        <div
                        dangerouslySetInnerHTML={
                            { __html: goalInfo.requiredSkills }}
                        />
                    </div>
                    <div className="goal-conclucsion-section">
                        <h3 className="text-lg font-semibold mb-4">Conclusion</h3>
                        <span
                        className="py-16"
                        dangerouslySetInnerHTML={
                            { __html: goalInfo.goalImp }}
                        />
                    </div>
                </div>
              </div>
              <div className="w-full lg:w-8/12 mx-auto mb-6 h-16 bg-blue-custom-light rounded-lg flex justify-center items-center">
                <span
                  onClick = {() => {
                    window.location = `https://app.jobacademy.ai/goalprofile/${goalInfo._id}`;
                  }}
                  className="text-2xl text-blue-500 hover:text-blue-600 p-4 cursor-pointer"
                >Set this goal</span>
              </div>
            </div>
          </div>
        )
    }
}
export default withRouter(Profile);
