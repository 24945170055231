import React from 'react';
import Index from '../views/index';
import { generalConfigs } from '../components/Pages/General/generalConfigs';
import { servicesConfigs } from '../components/Pages/Services/servicesConfigs';


const routes = [
    ...generalConfigs,
    ...servicesConfigs,
  {
    path: '/',
    component: () => <Index />,
  },
];
export default routes;