import React from 'react';
import { Helmet } from 'react-helmet';
export default function TermsAndConditions() {
  return (
    <div className="container mx-auto lg:py-10">
      <div className="flex flex-wrap items-center">
        <div className="w-full lg:w-8/12 mx-auto px-4 leading-relaxed">
          <Helmet >
            <title>Jobacademy - Job ready platform</title>
            <meta
                name="description"
                content="Jobacademy Job Ready platform helps job seekers achieve their job goal by connecting them with real time experienced professionals (Job Gurus) for mentorship, learning and skill verification."
            />
            <meta property="og:title" content="Jobacademy - Job ready platform" />
            <meta property="og:url" content="https://jobacademy.ai" />
            <meta property="og:image" itemprop="image" content="https://jobacademy.ai/jobacademy-social.png" />
          </Helmet>
          <div className="text-justify">
      <h3 className="text-2xl font-semibold py-3">Terms and conditions</h3>
      <p className="pb-6">These terms and conditions outline the rules and regulations for the use
        of Jobacademy Website, located at https://www.jobacademy.ai
      </p>
      <p className="pb-6">
        By accessing this website we assume you accept these terms and conditions.
        Do not continue to use Jobacademy if you do not agree to take all of the terms and
        conditions stated on this page.
      </p>
      <p className="pb-6">
        The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all
        Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company’s
        terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties",
        or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of
        payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the
        express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services, in
        accordance with and subject to, prevailing law of Netherlands. Any use of the above terminology or other words in
        the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring
        to same.
      </p>
      </div>
      <div className="text-justify">
        <h3 className="text-2xl font-semibold py-3">Cookies:</h3>
        <p className="pb-6">We employ the use of cookies. By accessing Jobacademy, you agreed to use cookies in agreement with the Jobacademy
            Privacy Policy.</p>
        <p className="pb-6">Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our
            website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our
            affiliate/advertising partners may also use cookies.</p>
            </div>
      <div className="text-justify">
        <h3 className="text-2xl font-semibold py-3">License:</h3>
        <p className="pb-6">Unless otherwise stated, Jobacademy and/or its licensors own the intellectual property rights for all material on
            Jobacademy. All intellectual property rights are reserved. You may access this from Jobacademy for your own personal use
            subjected to restrictions set in these terms and conditions.</p>
        <p className="pb-6">You must not:</p>
        <ul className="policy-list">
            <li>Republish material from Jobacademy</li>
            <li>Sell, rent or sub-license material from Jobacademy</li>
            <li>Reproduce, duplicate or copy material from Jobacademy</li>
            <li>Redistribute content from Jobacademy</li>
        </ul>
        <p className="pb-6">This Agreement shall begin on the date hereof.</p>
        <p className="pb-6">Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas
            of the website. Jobacademy does not filter, edit, publish or review Comments prior to their presence on the website.
            Comments do not reflect the views and opinions of Jobacademy ,its agents and/or affiliates. Comments reflect the views
            and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, Jobacademy
            shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of
            any use of and/or posting of and/or appearance of the Comments on this website.</p>
        <p className="pb-6">Jobacademy reserves the right to monitor all Comments and to remove any Comments which can be considered
            inappropriate, offensive or causes breach of these Terms and Conditions.</p>
            </div>
      <div className="text-justify">
        <h3 className="text-2xl font-semibold py-3">You warrant and represent that:</h3>
        <ul className="policy-list">
            <li>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</li>
            <li>The Comments do not invade any intellectual property right, including without limitation copyright, patent or
                trademark of any third party;</li>
            <li>The Comments do not contain any defamatory, libellous, offensive, indecent or otherwise unlawful material which
                is an invasion of privacy</li>
            <li>The Comments will not be used to solicit or promote business or custom or present commercial activities or
                unlawful activity.</li>
        </ul>
        <p className="pb-6">You hereby grant Jobacademy a non-exclusive license to use, reproduce, edit and authorise others to use, reproduce
            and edit any of your Comments in any and all forms, formats or media.
            Hyperlinking to our Content</p>
        <p className="pb-6">The following organisations may link to our Website without prior written approval:</p>
        <ul className="policy-list">
            <li>Government agencies;</li>
            <li>Search engines;</li>
            <li>News organisations;</li>
        </ul>
        </div>
      <div className="text-justify">
        <h3 className="text-2xl font-semibold py-3">iFrames:</h3>
        <p className="pb-6">Without prior approval and written permission, you may not create frames around our Webpages that alter in any way
            the visual presentation or appearance of our Website.</p>
            </div>
      <div className="text-justify">
        <h3 className="text-2xl font-semibold py-3">Content Liability:</h3>
        <p className="pb-6">We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us
            against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted
            as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other
            violation of, any third party rights.</p>
            </div>
      <div className="text-justify">
        <h3 className="text-2xl font-semibold py-3">Your Privacy:</h3>
        <p className="pb-6">Please read Privacy Policy</p>
        </div>
      <div className="text-justify">
        <h3 className="text-2xl font-semibold py-3">Reservation of Rights:</h3>
        <p className="pb-6">We reserve the right to request that you remove all links or any particular link to our Website. You approve to
            immediately remove all links to our Website upon request. We also reserve the right to amen these terms and
            conditions and it’s linking policy at any time. By continuously linking to our Website, you agree to be bound to and
            follow these linking terms and conditions.</p>
            </div>
      <div className="text-justify">
        <h3 className="text-2xl font-semibold py-3">Removal of links from our website:</h3>
        <p className="pb-6">If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any
            moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.
        </p>
        <p className="pb-6">We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy;
            nor do we promise to ensure that the website remains available or that the material on the website is kept up to
            date.</p>
            </div>
      <div className="text-justify">
        <h3 className="text-2xl font-semibold py-3">Disclaimer:</h3>
        <p className="pb-6">To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions
            relating to our website and the use of this website. Nothing in this disclaimer will:</p>
        <ul className="policy-list">
            <li>limit or exclude our or your liability for death or personal injury;</li>
            <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
            <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
            <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
        </ul>
        <p className="pb-6">The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject
            to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities
            arising in contract, in tort and for breach of statutory duty.</p>
        <p className="pb-6">As long as the website and the information and services on the website are provided free of charge, we will not be
            liable for any loss or damage of any nature.</p>
            </div>
      </div>
      </div>
      </div>
  );
}
