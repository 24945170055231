import React from "react";
import { Link } from 'react-router-dom';
export default function Footer() {
  return (
    <>
      <footer className="relative bg-gray-900 pt-8 pb-6">
        <div className="container w-full lg:w-11/12 mx-auto md:px-4">
          <div className="flex flex-wrap text-center lg:text-left">
            <div className="w-full lg:w-6/12 md:px-4">
              <div className="flex flex-wrap items-top mb-6">
                <div className="w-full lg:w-6/12 px-4">
                  <span className="block uppercase text-white text-md font-semibold mb-6">
                    General
                  </span>
                  <ul className="list-unstyled">
                    <li className="text-gray-400 hover:text-gray-700 font-semibold block pb-2 text-sm cursor-pointer">
                      <Link to="/about">About Us</Link>
                    </li>
                    <li className="text-gray-400 hover:text-gray-700 font-semibold block pb-2 text-sm cursor-pointer">
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li className="text-gray-400 hover:text-gray-700 font-semibold block pb-2 text-sm cursor-pointer">
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li className="text-gray-400 hover:text-gray-700 font-semibold block pb-2 text-sm cursor-pointer">
                      <Link to="/terms-conditions">Terms & condictions</Link>
                    </li>
                    <li className="text-gray-400 hover:text-gray-700 font-semibold block pb-2 text-sm cursor-pointer">
                      <Link to="/faq">FAQ</Link>
                    </li>
                  </ul>
                </div>
                <div className="w-full lg:w-6/12 px-4 sm:mt-20">
                  <span className="block uppercase text-white text-md font-semibold mb-6">
                    Services
                  </span>
                  <ul className="list-unstyled">
                    <li className="text-gray-400 hover:text-gray-700 font-semibold block pb-2 text-sm cursor-pointer">
                      <Link to="/job-goals">Job Goals</Link>
                    </li>
                    <li className="text-gray-400 hover:text-gray-700 font-semibold block pb-2 text-sm cursor-pointer">
                      <Link to="/courses">Courses</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-6/12 md:px-4">
              <div className="w-full lg:w-10/12 px-4 ml-auto">
                <h4 className="text-white text-2xl font-semibold">Let's keep in touch!</h4>
                <h5 className="text-sm mt-0 mb-2 text-gray-500">
                  Find us on any of these platforms, we respond 1-2 business days.
                </h5>
                <div className="mt-6 lg:mb-0 mb-6">
                  {/* <button
                    className="bg-white text-blue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                  >
                    <i className="fab fa-twitter"></i>
                  </button> */}
                  <a href="#" target="__blank">
                    <button
                      className="bg-white text-blue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                      type="button"
                    >
                      <i className="fab fa-facebook-square"></i>
                    </button>
                  </a>
                  <a href="#" target="__blank">
                    <button
                      className="bg-white text-pink-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                      type="button"
                    >
                      <i class="fab fa-linkedin"></i>
                    </button>
                  </a>
                </div>
                {/* <div className="relative w-full mb-3 mt-8">
                  <input
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="Full Name *"
                  />
                </div>

                <div className="relative w-full mb-3">
                  <input
                    type="email"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="Email *"
                  />
                </div>

                <div className="relative w-full mb-3">
                  <textarea
                    rows="4"
                    cols="80"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                    placeholder="Type a message..."
                  />
                </div>
                <div className="mt-6 flex justify-end">
                  <button className="text-white text-md font-semibold border-2 border-gray-400 rounded py-2 px-4">Send Message</button>
                </div> */}
              </div>
            </div>
          </div>
          <hr className="my-6 border-gray-400" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm text-gray-200 font-semibold py-1">
                Jobacademy © {new Date().getFullYear()}. All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
