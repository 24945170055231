import React from 'react';
import { Helmet } from 'react-helmet';
export default function AboutUs() {
  return (
    <div className="container mx-auto lg:py-10">
      <div className="flex flex-wrap items-center">
        <div className="w-full lg:w-8/12 mx-auto px-4 leading-relaxed">
          <Helmet >
            <title>Jobacademy - Job ready platform</title>
            <meta
              name="description"
              content="Jobacademy Job Ready platform helps job seekers achieve their job goal by connecting them with real time experienced professionals (Job Gurus) for mentorship, learning and skill verification."
            />
            <meta property="og:title" content="Jobacademy - Job ready platform" />
            <meta property="og:url" content="https://jobacademy.ai" />
            <meta property="og:image" itemprop="image" content="https://jobacademy.ai/jobacademy-social.png" />
          </Helmet>
          <div className="text-justify">
            <h3 className="text-2xl font-semibold py-3">About us</h3>
            <p className="pb-6">
              Jobacademy – a job ready platform started its way in 2018 with an insight of building a strong network of people, helping each other grow through learning, mentoring and referring people.
            </p>
            <p className="pb-6">
              It helps people reach their dreams through consistent progress in achieving their objectives.
            </p>
            <p className="pb-6">
              Assist job seekers in achieving their job goals by connecting them with real time experienced professionals (Job Gurus) for mentorship, learning and skill verification. It also connects job seekers with organisations as trusted job ready resources and helps them to get hired.
            </p>
          </div>
          <div className="text-justify">
            <h3 className="text-2xl font-semibold py-3">Our Uniqueness</h3>
            <p className="pb-6">
              Jobacademy unique platform makes people understand a better suitable career according to their education and experience giving enormous options to choose their goals.
            </p>
            <p className="pb-6">
              The learning methodology in Jobacademy helps people acquire fundamentals with practical training and make them job ready by providing real time projects.
            </p>
            <p className="pb-6">
              We give the students an opportunity to learn technical skills and professional skills all in one platform and be ready for their future endeavour.Also many companies are collaborated with us to provide ideal job assistance to students.
            </p>
            <p className="pb-6">
              It is the responsibility of Jobacademy to train people on the planet, the necessary skills recquired to gain confidence in the corporate world.
            </p>
          </div>
          <div className="text-justify">
            <h3 className="text-2xl font-semibold py-3">Mission</h3>
            <p className="pb-6">
              Our mission is to empower every job seeker to aspire and achieve their dreams in the software industry.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
