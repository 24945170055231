import React from 'react';
import { LinearProgress } from '@material-ui/core';

const Loader = props => (
  <div className="flex flex-1 flex-col items-center justify-center h-500-px z-10 w-full">
    <p className="text-lg text-gray-800">Loading...</p>
    <LinearProgress className="w-320 jobacademy-linear-progress-bar" />
  </div>
);
export default Loader;
