import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import basepath from 'basepath';
import Loader from 'Loader';
import {
  Typography, Button, Grid, List, ListItem, ListItemText, Accordion, AccordionSummary, AccordionDetails,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import axios from 'axios';

function Videoplayer({ src }) {
  return (
    <div tabIndex="-1" className="videoplayer w-full">
      <video className="classes-profile-video1" controls src={`${basepath}/${src}`} />
    </div>
  );
}

class CourseProfile extends Component {
  constructor(props){
    super(props);
    this.state = {
      singleclassdata: undefined,
      ratings : 0,
      contentLoader: false,
      vid: true,
      expanded: '',
    }
  }
  
  componentDidMount() {
    const _this = this;
    this.myDivToFocus = React.createRef();
    axios.get(`${basepath}/singleclassprofile/${this.props.location.state.courseId}`)
   .then(function (response) {
     _this.setState({ singleclassdata: response.data.result[0], contentLoader: true });
   })
   .catch(function (error) {
     console.log(error);
   });
 }
  handleChange = (event, value) => {
    this.setState({ value });
  };
  handlePanelChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };
  playVid = () => {
    this.setState({ vid: false });
  }
  static dateLongStr(date) {
    const mS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    const month = mS[date.getMonth()];
    const dt = date.getDate();
    const year = date.getFullYear();
    return `${dt} ${month} ${year}`;
  }
  render() {
    const { contentLoader, singleclassdata, vid, expanded } = this.state;
    const ratingArr = [1, 2, 3, 4, 5];
    return !contentLoader ? (
      <Loader />
    ) : (
      <div className="w-full h-full p-5">
        <Helmet>          
          <title>{singleclassdata.className}</title>
          <meta property="og:title" content="TrendingCourses" />
          <meta property="og:description" content="Trending Courses of Jobacademy -- taught under the supervision of the  expertise with real time professionals." />
          <meta property="og:image" content="https://jobacademy.ai/jobacademy-social.png" />
        </Helmet>
        {singleclassdata !== undefined && singleclassdata !== '' ? (
            <Grid container className="w-full">
              <Grid item md={12} xs={12} className="lg:flex lg:m-4">
                <Grid item md={8} xs={12} className="md:px-4">
                  <h4 className="text-lg font-semibold mt-2 truncate">{singleclassdata.className}</h4>
                  <p className="text-sm text-gray-500 mb-2">{`by ${singleclassdata.mentorData[0].firstName} ${singleclassdata.mentorData[0].lastName}`}</p>
                  <p
                    onClick={() => {
                      if (this.myDivToFocus.current) {
                        this.myDivToFocus.current.scrollIntoView({ 
                            behavior: "smooth", 
                            block: "nearest"
                        })
                      }
                    }}
                    className="text-sm cursor-pointer mb-4"
                  >
                    <span className="cursor-pointer text-orange-500 mr-8">
                      {ratingArr.map((i, k) => (i <= singleclassdata.avg ? (
                      <span key={`${k}rate`} >
                        <i className="fas fa-star"></i>
                        </span>
                        ) : (
                        <span key={`${k}rate`} >
                            <i className="far fa-star "></i>
                        </span>
                      )))}
                    </span>
                    <span className="cursor-pointer text-grey-darker mr-8 text-14">
                    {`(${singleclassdata.ratingsData.length})`}</span>
                  </p>
                  <p className="text-lg pt-6">
                    {`${singleclassdata.minPrice ? singleclassdata.minPrice : 0} INR`}
                  </p>
                </Grid>
                <Grid item sm={4} md={4} className="sm:pr-20 pt-20 sm:pt-0 w-full">
                  {vid ? (
                    <div className="video-card-layer video-card-layer-160">
                      <div className="video-mock-layer flex justify-center items-center border-rds-7">
                        <i className="fa fa-play-circle video-play-vid cursor-pointer text-4xl" onClick={this.playVid}></i>
                      </div>
                      <img alt=" " src={`${basepath}/uploads/videothumbnail/360/${singleclassdata.videoThumbnail}`} className="w-full rounded-lg" />
                    </div>
                  ) : singleclassdata && (singleclassdata.video.split('/')[0].trim('') === 'uploads' || singleclassdata.video.split('\\')[0].trim('') === 'uploads') ? (
                    <Videoplayer src={singleclassdata ? singleclassdata.video : null} className="h-120 w-full" />
                  ) : (
                    <iframe
                      title="session video"
                      allowFullScreen="allowfullscreen"
                      mozallowfullscreen="mozallowfullscreen" 
                      msallowfullscreen="msallowfullscreen" 
                      oallowfullscreen="oallowfullscreen" 
                      webkitallowfullscreen="webkitallowfullscreen"
                      name={singleclassdata.video ? singleclassdata.video.split('?')[1].split('=')[1] : ''}
                      className="w-full h-120 rounded-lg"
                      src={singleclassdata.video}
                    />
                  )}
                  <div className="w-full mx-auto flex justify-center mt-4">
                    <Button
                      fullWidth
                      onClick = {() => {
                        window.location = `https://app.jobacademy.ai/classes/profile/${singleclassdata._id}`
                      }}
                      className="cursor-pointer btn-custom"
                      color="primary"
                      variant="outlined"
                    >
                      <i className="fas fa-shopping-cart mr-2"></i> Buy class
                    </Button>
                  </div>
                </Grid>
              </Grid>
              <Grid container className="md:px-4 mt-6">
                <h4 className="text-base font-semibold py-3">Class Timings:</h4>
                {singleclassdata && singleclassdata.scheduleData.length > 0
                  ? singleclassdata.scheduleData.map((val, key) => (
                    <Grid key={`class-${key}`} item md={12} xs={12} className="w-full p-5 mb-custom-ip lg:flex justify-between bg-blue-custom-light rounded-lg">
                      <Grid item md={3} xs={12}>
                        <p className="text-sm text-gray-500">
                          Time period
                        </p>
                        <p className="text-sm text-gray-900">
                          {`${moment(new Date(val.startDate).toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })).format('ll')} - ${moment(new Date(val.endDate).toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })).format('ll')}`}
                        </p>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <p className="text-sm text-gray-500 mb-t-space">
                        Timings
                        </p>
                        <p className="text-sm text-gray-900">
                          {new Date(val.start).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
                          {` to ${new Date(val.end).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`}
                          {` (${moment.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).zoneAbbr()})`}
                        </p>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <p className="text-sm text-gray-500 mb-t-space">
                        Weeks
                        </p>
                        <p className="text-sm text-gray-900">
                          {val.weeks.map((w, k) => (
                            k + 1 === val.weeks.length
                              ? w : `${w}, `
                          ))}
                        </p>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <p className="text-sm text-gray-500 mb-t-space">
                        Members
                        </p>
                        <p className="text-sm text-gray-900">
                        {`few remaining / ${val.maxPeople}`}
                        </p>
                      </Grid>
                    </Grid>
                  )) : null}
                </Grid>
              </Grid>
        ) : ''}
        {singleclassdata !== undefined ? (
          <Grid container className="md:px-4 mt-3">
            <Grid item md={12} xs={12} className="p-12">
              <h4 className="text-base font-semibold py-3">Syllabus</h4>
              {singleclassdata.syllabus && singleclassdata.syllabus.length > 0 ? (
                singleclassdata.syllabus.map((val, key) => (
                  <Accordion
                    key={`panel${key}`}
                    expanded={expanded === `panel${key}`}
                    onChange={this.handlePanelChange(`panel${key}`)}
                    className="course-content-section"
                  >
                    <AccordionSummary
                      className="course-title-label" 
                      expandIcon={<span className="session-expand-icon shadow flex justify-center items-center"><i className="fas fa-chevron-down"></i></span>}
                    >
                      <div className="flex w-full">
                        <p className="text-sm text-gray-600 font-semibold">
                          {val.syllabusTitle}
                        </p>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      className="session-objective"
                    >
                      <Typography
                        className="flex py-4"
                      >
                        <span className="text-sm text-gray-700 font-semibold">Objectives:</span>
                        <span
                          className="text-sm text-gray-700 ml-3"
                          dangerouslySetInnerHTML={{
                            __html: val.objectives,
                          }}
                        />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ))) : (
                  <span>Mentor didn't add any description</span>
              )}
            </Grid>
          </Grid>
        ) : null}
        {singleclassdata !== undefined ? (
          <Grid container className="md:px-4 mt-6" ref={this.myDivToFocus}>
            <h4 className="text-base font-semibold py-3 w-full">Reviews & Ratings</h4>
            <Typography className="w-full">
              <span className={`${singleclassdata.avg < 2 ? 'text-gray-700' : singleclassdata.avg < 3 ? 'text-orange-500' : 'text-green-500'} text-lg mr-3`}>
                {singleclassdata.avg} &#9733;
              </span>
              {singleclassdata.ratingsData !== undefined ? (
                <span className="text-sm">{`(${singleclassdata.ratingsData.length} ratings and reviews)`}</span>
              ) : (
                null
              )}
            </Typography>
                {singleclassdata &&  singleclassdata.ratingsData && singleclassdata.ratingsData.length > 0
                  ? singleclassdata.ratingsData.map((rateVal, rateKey) => (
                    <List key={rateKey} className=" w-full jobacademy-ratings-list">
                      <ListItem className="px-20 py-0">
                        <ListItemText
                          className="jobacademy-ratings-list-view"
                          primary={rateVal.userName}
                          secondary={(
                            <React.Fragment>
                              <Typography component="span" color="textPrimary">
                                {ratingArr.map((i, k) => (i <= rateVal.rate ? 
                                <span key={`rate-${k}`} className="text-12 mt-4 text-orange-500"><i className="fas fa-star"></i></span>
                                 : <span key={`rate-${k}`} className="text-12 mt-4 text-orange-500"><i className="far fa-star"></i></span>))}
                              </Typography>
                              {` — ${rateVal.description}`}
                            </React.Fragment>
                          )}
                        />
                      </ListItem>
                    </List>
                  )) : (
                    <div className="w-full bg-blue-custom-light p-4 mt-6 text-sm flex justify-center items-center rounded-lg">
                      No reviews found.
                    </div>
                  )
                }
          </Grid>
        ) : null}
      </div>
    )
  }
}
export default withRouter(CourseProfile);
