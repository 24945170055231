import { withRouter } from 'react-router-dom';
import { Component } from 'react';
import $ from 'jquery';
class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      $('html,body').scrollTop(0);
    }
  }
  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
