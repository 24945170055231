import React from 'react';
import { Helmet } from 'react-helmet';
export default function PrivacyPolicy() {
  return (
    <div className="container mx-auto lg:py-10">
      <div className="flex flex-wrap items-center">
        <div className="w-full lg:w-8/12 mx-auto px-4 leading-relaxed">
          <Helmet >
            <title>Jobacademy - Job ready platform</title>
            <meta
              name="description"
              content="Jobacademy Job Ready platform helps job seekers achieve their job goal by connecting them with real time experienced professionals (Job Gurus) for mentorship, learning and skill verification."
            />
            <meta property="og:title" content="Jobacademy - Job ready platform" />
            <meta property="og:url" content="https://jobacademy.ai" />
            <meta property="og:image" itemprop="image" content="https://jobacademy.ai/jobacademy-social.png" />
          </Helmet>
          <div className="text-justify">
            <h3 className="text-2xl font-semibold py-3">Jobacademy Privacy Policy</h3>
            <p className="pb-6">Jobacademy - Job Ready platform helps job seekers achieve their job goal by connecting 
              them with real time experienced professionals (Job Gurus) for mentorship, learning and 
              skill verification. Jobacademy also connects job seekers with organisations as trusted 
              job ready resources and helps them to get hired.</p>
            <p className="pb-6">Jobacademy Privacy Policy describes how your personal information is collected, used, and shared when 
              you visit or make a purchase from our application (the “Site”).</p>
          </div>
          <div className="text-justify">
            <h3 className="text-2xl font-semibold py-3">Personal information we collect:</h3>
            <p className="pb-6">
            When you visit the Jobacademy Site, we automatically collect certain information about your device, 
            including information about your web browser, IP address, time zone, and some of the cookies 
            that are installed on your device. Additionally, as you browse the Site, we collect information 
            about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. 
            We refer to this automatically collected information as “Device Information.”
            </p>
          </div>
          <div className="text-justify">
            <h3 className="text-2xl font-semibold py-3">
            We collect Device Information using the following technologies:
            </h3>
            <ul className="policy-list">
              <li>
              “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.
              </li>
              <li>
              “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.
              </li>
              <li>
              “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.
              </li>
            </ul>
            <p className="pb-6">
            Additionally when you make a purchase or attempt to make a purchase through the Site, 
            we collect certain information from you, including your name, billing address, 
            payment information (including credit card numbers,debit cards numbers, net banking, 
            mobile wallets  etc,..), email address, and phone number.  We refer to this information 
            as “Order Information.”
            </p>
            <p className="pb-6">
            When we talk about “Personal Information” in this Privacy Policy, we are talking both 
            about Device Information and Order Information.
            </p>
          </div>
          <div className="text-justify">
            <h3 className="text-2xl font-semibold py-3">
            How do we use your personal information?
            </h3>
            <p className="pb-6">
            We use the Order Information that we collect generally to fulfil any orders placed 
            through the Site (including processing your payment information, arranging for shipping, 
            and providing you with invoices and/or order confirmations).  Additionally, we use this Order Information to communicate with you. Screen our orders for potential risk or fraud; and
            We use the Device Information that we collect to help us screen for potential risk and 
            fraud (in particular, your IP address), and more generally to improve and optimise our 
            Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).
            </p>
          </div>
          <div className="text-justify">
            <h3 className="text-2xl font-semibold py-3">Sharing your personal information</h3>
            <p className="pb-6">We share your Personal Information with third parties to help us use your Personal 
              Information, as described above.  For example, we share your resumes to the companies 
              which are register in our application.  We also use Google Analytics to help us understand 
              how our customers use the Site--you can read more about how Google uses your Personal 
              Information here:  https://www.google.com/intl/en/policies/privacy/.  You can also opt-out of Google Analytics here:  https://tools.google.com/dlpage/gaoptout.
              Finally, we may also share your Personal Information to comply with applicable laws and 
              regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.
            </p>
          </div>
          <div className="text-justify">
            <h3 className="text-2xl font-semibold py-3">
              Do not track
            </h3>
            <p className="pb-6">Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.</p>
          </div>
          <div className="text-justify">
            <h3 className="text-2xl font-semibold py-3">
              Data retention
            </h3>
            <p className="pb-6">
            When you enter your professional, personal information or payment details in our site or application,we will maintain it very confidential.You can personally delete or you can ask us to delete that information.
            </p>
          </div>
          <div className="text-justify">
            <h3 className="text-2xl font-semibold py-3">
              Changes
            </h3>
            <p className="pb-6">
            We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.
            </p>
          </div>
          <div className="text-justify">
            <h3 className="text-2xl font-semibold py-3">Contact Us</h3>
            <p className="pb-6">For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at support@jobacademy.ai</p>
          </div>
      </div>
      </div>
      </div>
  );
}
