/*eslint-disable*/
import React, { useRef } from "react";
import Slider from "react-slick";
import { Helmet } from 'react-helmet';
import { useHistory } from "react-router-dom";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function Index() {
  const history = useHistory();
  const guidRef = useRef(null);
  const clsRef = useRef(null);
  const tutorRef = useRef(null);
  const jobRef = useRef(null);
  const testimonialSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: window.innerWidth > 600 ? 3 : 1,
    slidesToScroll: window.innerWidth > 600 ? 3 : 1,
    autoplay: false,
    autoplaySpeed: 10000,
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
  };
  const guidelines = [
    'Student strength and weakness will be determined',
    'Discard confusion related to course selection',
    'Understand from our experts regarding trending technologies',
    'Develop student’s knowledge on technology with Job Guru (Tutor)',
    'Seek our expert team support and motivation'
  ];
  const mentorsArr = [
    {
      name: 'Kishore Bezawada',
      role: 'Sales Manager',
      image: 'team-1-800x800.jpg',
      noOfAssist: '60',
      description: 'Putting together a page has never beeneasier than matching together pre-made components.'
    },
    {
      name: 'Vamshi Roju',
      role: 'Precident',
      image: 'team-1-800x800.jpg',
      noOfAssist: '260',
      description: 'Putting together a page has never beeneasier than matching together pre-made components.'
    },
    {
      name: 'Sreekanth Mandapati',
      role: 'CTO & Co founder',
      image: 'team-1-800x800.jpg',
      noOfAssist: '560',
      description: 'Putting together a page has never beeneasier than matching together pre-made components.'
    },
    {
      name: 'Krishna Thangallapally',
      role: 'Professor',
      image: 'team-1-800x800.jpg',
      noOfAssist: '60',
      description: 'Putting together a page has never beeneasier than matching together pre-made components.'
    },
  ];
  const courseArr = [
    {
      title: 'MEAN/MERN stack',
      labels: 'nodejs mean mern',
    },
    {
      title: 'Java full stack',
      labels: 'java',
    },
    {
      title: 'Python full stack',
      labels: 'python',
    },
    {
      title: 'AWS cloud engineer',
      labels: 'aws cloud',
    },
    {
      title: 'DevOps developer',
      labels: 'devops',
    },
    {
      title: 'Personality development',
      labels: 'personality',
    },
  ];
  const testimonialArr = [
    {
      name: 'Deepika Maheswari',
      review: 'To be honest, this was my first online course. I am so glad to enrolled in Full Stack Developer of Node js and React js course. It was a wonderful experience. The content is very informative. I really liked the flexibility of this course. The tutor is knowledgeable and his skills of explaining the concepts are awesome. The classes are very detailed and organized.',
      image: 'deepika.jpg'
    },
    {
      name: 'Manasa Pala',
      review: 'Honestly JOBACADEMY is a great platform where we can acquire extreme level of knowledge in PYTHON FULL STACK. Having such a knowledge full trainer(Anirudh Chakravarthi )helps us to make our work done in time and made us to think more smarter than usual. The way they interact with us while clearing doubts drives us to do our best even through online.',
      image: 'manasa.JPG'
    },    
    {
      name: 'Mani Sharath Chandra',
      review: 'Actually, I have no hope on this. But after joining my views are totally gone wrong. Platform and tutor are good. Subject what we are going to learn will be even more good.',
      image: 'sharath.jpg'
    },
    {
      name: 'Mona Honey',
      review: 'I have learn node js full stack in Jobacademy. The tutor is very patience and explained evereything from the basics. So that I learned coding very  easily. They will teach with real time synonyms so It helped me to get place in the MNC company in which I am working presently.',
      image: 'mona.jpg'
    },
    {
      name: 'Venkata Manikanta',
      review: 'Great environment to learn and gain the knowledge with a proper guidance by the experts. They share there work experience and provide industry level training. I feel this is the right platform for the new learners to enhance and exhibit there skills.',
      image: 'manikanta.JPG'
    },
    {
      name: 'Gowthami Kamuni',
      review: 'I am  very happy to be a part of JOBACADEMY platform which helps me to gain much knowledge through online and the way they teach inspires us lot to work hard as well as smarter and it also provides good platform to start my career as  a fresher very immediate to the course completion.',
      image: 'gowthami.jpg'
    },
  ];
  return (
    <>
      {/* <Slider {...settings}> */}
      <Helmet >
            <title>Jobacademy - Job ready platform</title>
            <meta
              name="description"
              content="Jobacademy Job Ready platform helps job seekers achieve their job goal by connecting them with real time experienced professionals (Job Gurus) for mentorship, learning and skill verification."
            />
            <meta property="og:title" content="Jobacademy - Job ready platform" />
            <meta property="og:url" content="https://jobacademy.ai" />
            <meta property="og:image" itemprop="image" content="https://jobacademy.ai/jobacademy-social.png" />
          </Helmet>
        <section className="header relative items-center flex h-screen max-h-860-px bg-sky-blue">
          <div className="container h-full mx-auto items-center flex flex-wrap px-4">
            <div className="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 lg:px-4">
              <div className="w-full lg:w-10/12 mx-auto pb-20">
                <h2 className="font-semibold text-3xl text-gray-700 uppercase">
                Quick & easy steps to <br /> get the Job
                  {/* Get Your New Job <br /> By 3 Easiest Steps Only */}
                </h2>
                <ul className="list-none mt-6">
                  <li>
                    <div className="flex items-center">
                      <div>
                        <span className="inline-block py-1 px-2 text-green-500 text-lg font-bold">
                        ✔
                        </span>
                      </div>
                      <div>
                        <h4 className="text-lg leading-relaxed text-gray-600">
                          Make the right choice with Guidance
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex items-center">
                      <div>
                        <span className="inline-block py-1 px-2 text-green-500 text-lg font-bold">
                        ✔
                        </span>
                      </div>
                      <div>
                        <h4 className="text-lg leading-relaxed text-gray-600">
                          Learn the Job ready skills
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex items-center">
                      <div>
                        <span className="inline-block py-1 px-2 text-green-500 text-lg font-bold">
                        ✔
                        </span>
                      </div>
                      <div>
                        <h4 className="text-lg leading-relaxed text-gray-600">
                          Attain your dream Job
                        </h4>
                      </div>
                    </div>
                  </li>
                </ul>
                <div className="mt-12">
                  <a
                    href="https://app.jobacademy.ai/register"
                    className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blue-500 active:bg-blue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                  >
                    Register with us
                  </a>
                </div>
              </div>
            </div>
            <div className="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 mr-auto px-4">
              {/* <img
                className="max-w-full"
                src={require("assets/img/theme/5514.jpg")}
                alt="..."
              /> */}
            </div>
          </div>
        </section>
        {/* <section className="header relative items-center flex h-screen max-h-860-px">
          <div className="container h-full mx-auto items-center flex flex-wrap px-4">
            <div className="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
              <div className="w-full lg:w-10/12 mx-auto pb-20">
                <h2 className="font-semibold text-4xl text-gray-700 uppercase">
                  You Can Get The Job <br />
                  By 3 Easiest Steps
                </h2>
                <p className="mt-4 text-lg leading-relaxed text-gray-600">
                Plan, Prepare and Find your first/next job
                </p>
                <div className="mt-12">
                  <a
                    href="https://app.jobacademy.ai"
                    className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blue-500 active:bg-blue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                  >
                    Get started
                  </a>
                </div>
              </div>
            </div>
            <div className="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 mr-auto px-4">
              <img
                className="max-w-full"
                src={require("assets/img/theme/course.png")}
                alt="..."
              />
            </div>
          </div>
        </section>
        <section className="header relative items-center flex h-screen max-h-860-px">
          <div className="container h-full mx-auto items-center flex flex-wrap px-4">
            <div className="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
              <div className="w-full lg:w-10/12 mx-auto pb-20">
                <h2 className="font-semibold text-4xl text-gray-700 uppercase">
                  You Can Get The Job By 3 Easiest Steps
                </h2>
                <p className="mt-4 text-lg leading-relaxed text-gray-600">
                Plan, Prepare and Find your first/next job
                </p>
                <div className="mt-12">
                  <a
                    href="https://app.jobacademy.ai"
                    className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blue-500 active:bg-blue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                  >
                    Get started
                  </a>
                </div>
              </div>
            </div>
            <div className="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 mr-auto px-4">
              <img
                className="max-w-full"
                src={require("assets/img/theme/main3.jpg")}
                alt="..."
              />
            </div>
          </div>
        </section> */}
      {/* </Slider> */}
      <section id="guidRef" className="pb-6 relative pt-20" ref={guidRef}>
        <div className="container mx-auto">
          <div className="flex flex-wrap items-center">
            <div className="w-full lg:w-11/12 mx-auto px-4">
              <div className="relative flex flex-col">
                <div className="flex pb-4">
                  <h6 className="text-3xl mb-1 font-semibold inline-flex items-center justify-center">
                    Guidance
                  </h6>
                </div>
                <p className="mb-4 text-gray-800">
                  Students will be provided with best career guidance and trending technologies in IT companies. Free guidance provided by our expert team will help the students to achieve the job goal. Student’s choice will be taken into consideration while counselling and suitable technology will be recommended.
                </p>
              </div>
            </div>
            <div className="w-full lg:w-11/12 mx-auto px-4 md:px-12 lg:flex py-6 lg:h-screen">
              <div className="w-full md:w-6/12 flex flex-wrap items-center guidance-img h-half">
                {/* <img
                  alt="..."
                  className="max-w-full"
                  src={require("assets/img/theme/guidance.png")}
                /> */}
              </div>
              <div className="w-full md:w-6/12 guidance lg:pt-0 pt-6">
                <div className="flex items-center justify-center">
                  <ul className="list-none mt-6">
                    <li className="pb-2 text-sm font-bold">
                      Guideline highlights to consider:
                    </li>
                    {guidelines.map((gval, gkey) => (
                      <li key={`guideline-${gkey}`} className="py-2">
                        <div className="flex items-center bg-white shadow rounded-lg">
                          <span className="inline-block guide-count px-3 text-white bg-blue-600 rounded-tb-left">
                          {`0${gkey+1}`}
                          </span>
                          <h4 className="text-sm text-gray-900 px-3">
                            {gval}
                          </h4>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>              
            </div>
          </div>
        </div>
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap items-center">
            <div className="w-full lg:w-11/12 mx-auto px-4 h-80 bg-blue-custom-light rounded-lg flex justify-center items-center">
              <span className="text-2xl text-gray-900 p-4">For any queries and free assistance call on <b>+91-98488 04474</b></span>
              {/* <h6 className="text-lg font-semibold inline-flex items-center justify-center mt-6">
                Most popular mentors
              </h6>
              <div className="relative flex flex-wrap min-w-0 mt-5">
                {mentorsArr.map((mval, mkey) => (
                  <div key={`mentors-${mkey}`} className="w-full md:w-6/12 lg:w-3/12 md:px-4">
                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow rounded-lg">
                      <blockquote className="relative p-5 mentor-card">
                        <div className="flex">
                          <div className="text-gray-600 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                            <img src={require(`assets/img/${mval.image}`)} className="rounded-full" />
                          </div>
                          <h4 className="text-sm font-semibold text-gray-900 ml-3">
                            {mval.name}
                            <br />
                            <p className="text-sm font-normal text-gray-600">{mval.role}</p>
                          </h4>
                        </div>
                        <h5 className="text-sm">{`Number of assistes ${mval.noOfAssist}`}</h5>
                        <p className="text-sm mt-4 text-gray-600">
                          {mval.description}
                        </p>
                        <div className="mt-6 w-full flex justify-center">
                          <button className="text-gray-900 text-md border-2 border-gray-600 rounded py-2 px-4">Free Assistance</button>
                        </div>
                      </blockquote>
                    </div>
                  </div>
                ))}
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section id="clsRef" className="pb-6 relative bg-sky-blue pt-20" ref={clsRef}>
        <div className="container mx-auto">
          <div className="flex flex-wrap items-center">
            <div className="w-full lg:w-11/12 mx-auto px-4">
              <div className="relative flex flex-col">
                <div className="flex pb-4">
                  <h6 className="text-3xl mb-1 font-semibold inline-flex items-center justify-center">
                    Courses
                  </h6>
                </div>
                <p className="mb-4 text-gray-800">
                  Jobacademy keeps a close watch on the requirements in IT industry and offer courses accordingly. The best part in Jobacademy is tutor being active in IT field and offering the best guidance with live examples and concept based tasks.
                </p>
              </div>
              <div className="relative flex flex-col mb-8">
                <div className="coursecamp mt-6">
                  <ul className="lg:flex">
                    <li className="w-full md:w-6/12 lg:w-4/12 pb-16 md:pb-2">
                      <div className="course-img-section bg-blue-gredient mb-3">
                        <img width="100" src={require('assets/img/theme/course-skill.png')} />
                      </div>
                      <div className="coursecamp-info">
                        <h4 className="text-sm font-bold pb-6">Acquire skills to boost your career</h4>
                        <p className="text-sm text-gray-800">Student will be equipped with standardised syllabus designed with the help of experts to develop job ready skills</p>
                      </div>
                    </li>
                    <li className="w-full md:w-6/12 lg:w-4/12 pb-16 md:pb-2">
                      <div className="course-img-section bg-green-gredient mb-3">
                        <img width="100" src={require('assets/img/theme/course-tutor.png')} />
                      </div>
                      <div className="coursecamp-info">
                        <h4 className="text-sm font-bold pb-6">Learn from industry experts</h4>
                        <p className="text-sm text-gray-800">Students will learn the courses from real time experts in IT field. Real time scenarios from experts will help the students to gain knowledge</p>
                      </div>
                    </li>
                    <li className="w-full md:w-6/12 lg:w-4/12 pb-16 md:pb-2">
                      <div className="course-img-section bg-pink-gredient mb-3">
                        <img width="100" src={require('assets/img/theme/course-code.png')} />
                      </div>
                      <div className="coursecamp-info">
                        <h4 className="text-sm font-bold pb-6">Real time project with interactive tools</h4>
                        <p className="text-sm text-gray-800">Job Guru will assist the student from the scratch to develop the project. All the conceptual doubts will be cleared readily by our Job Guru</p>
                      </div>
                    </li>
                    <li className="w-full md:w-6/12 lg:w-4/12 md:pb-2">
                      <div className="course-img-section bg-orange-gredient mb-3">
                        <img width="100" src={require('assets/img/theme/course-liveclass.png')} />
                      </div>
                      <div className="coursecamp-info">
                        <h4 className="text-sm font-bold pb-6">Live classes with limited strength</h4>
                        <p className="text-sm text-gray-800">Not more than ten students will be in each class of Jobacademy. One on one attention will be given to every student</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <h6 className="text-lg font-semibold inline-flex items-center justify-center">
                Most trending courses
              </h6>
              <div className="relative flex flex-wrap min-w-0 mt-5">
                {courseArr.map((cVal, cKey) => (
                  <div key={`coursecard-${cKey}`} className="w-full md:w-6/12 lg:w-4/12 md:px-4">
                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow rounded-lg">
                      <blockquote className="relative p-5">
                        <div className="course-list">
                          <div
                            className={`cr-${cKey + 1} cursor-pointer`}
                            onClick={() => {
                              history.push({
                                pathname: `/courses/${cVal.title.toLowerCase().replace('/', '-').replaceAll(' ', '-')}`,
                                state: {
                                  labels: cVal.labels,
                                }
                              });
                            }}
                          >
                            <h4>
                              <div>{cVal.title}</div>
                            </h4>
                            {/* <span className="text-sm font-semibold">₹ 20000</span>
                            <div className="rating-wrap">
                              <span className="rating">
                                <i className="fas fa-star text-orange-500 mr-2"></i>
                                4.3 (22017 Ratings)
                              </span>
                              <span className="lrns">73007 Learners</span>
                            </div> */}
                          </div>
                        </div>
                      </blockquote>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="jobRef" className="pb-20 relative pt-20" ref={jobRef}>
        <div className="container mx-auto">
          <div className="flex flex-wrap items-center">
            <div className="w-full lg:w-11/12 mx-auto px-4">
              <div className="relative flex flex-col">
                <div className="flex pb-4">
                  <h6 className="text-3xl mb-1 font-semibold inline-flex items-center justify-center">
                    Placements
                  </h6>
                </div>
                <p className="mb-4 text-gray-800">
                  Jobacademy will help the students in verifying their skills, preparing resume, build the communication skills and approach different companies. Students will undergo interviews with guidance provided by our experts and will be provided with tremendous job opportunities.
                </p>
              </div>
              <div className="relative flex flex-wrap min-w-0 mt-5">
                <div className="w-full md:w-6/12 lg:w-6/12 md:px-4">
                  <img
                    alt="..."
                    className="max-w-full"
                    src={require("assets/img/theme/interview.jpg")}
                  />
                </div>
                <div className="w-full md:w-6/12 lg:w-6/12 md:px-4 lg:pt-12 lg:pt-0">
                  <div className="w-full pb-16 flex-col pt-16">
                    <div className="placement-img-section bg-green-gredient">
                      <img width="100" src={require('assets/img/theme/verify-process.png')} />
                    </div>
                    <div className="placement-content overflow-hidden">
                      <h4 className="text-sm font-bold pb-4 pt-2">Skill Verify Process</h4>
                      <p className="text-sm text-gray-800">Student will be handling a project which helps to create a flexible set of skill sets. The project will be approved by Job Guru and verified badge will be provided.</p>
                    </div>
                  </div>
                  <div className="w-full pb-6">
                    <div className="placement-img-section bg-pink-gredient">
                      <img width="100" src={require('assets/img/theme/mock-interview.png')} />
                    </div>
                    <div className="placement-content overflow-hidden">
                      <h4 className="text-sm font-bold pb-4 pt-2">Mock Interview</h4>
                      <p className="text-sm text-gray-800">Mock Interview will be conducted by technical team which will help the students to learn the interview strategies and overcome the interview fear. Immediate feedback will be given and areas of improvement will be suggested accordingly.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="tutorRef" className="bg-gray-900 overflow-hidden pt-20" ref={tutorRef}>
        <div className="container mx-auto pb-20 lg:pb-40">
          <div className="flex flex-wrap justify-center">
            <div className="w-full md:w-5/12 px-4 ml-auto mr-auto lg:mt-16">
              <h3 className="text-3xl mb-2 font-semibold leading-normal text-white">
                Become a tutor
              </h3>
              <p className="text-sm font-normal leading-relaxed mt-4 mb-4 text-gray-400">
              Becoming a tutor is really easy for real time working professionals with good technical and communication skills. Few simple steps needed to be followed to submit the course curriculum and the demo video.
              </p>
              <ul className="list-none mt-6">
                <li className="pb-2 text-sm font-bold text-gray-400">
                  Benefits for our Job Guru:
                </li>
                <li className="py-1">
                  <div className="flex items-center">
                    <div>
                      <span className="inline-block py-1 px-2 text-green-500 text-lg font-bold">
                      ✔
                      </span>
                    </div>
                    <div>
                      <h4 className="text-sm text-gray-400">
                        Jobacademy platform enables to reach more number of student’s
                      </h4>
                    </div>
                  </div>
                </li>
                <li className="py-1">
                  <div className="flex items-center">
                    <div>
                      <span className="inline-block py-1 px-2 text-green-500 text-lg font-bold">
                      ✔
                      </span>
                    </div>
                    <div>
                      <h4 className="text-sm text-gray-400">
                        Gaining a fruitful income with best teaching skills
                      </h4>
                    </div>
                  </div>
                </li>
                <li className="py-1">
                  <div className="flex items-center">
                    <div>
                      <span className="inline-block py-1 px-2 text-green-500 text-lg font-bold">
                      ✔
                      </span>
                    </div>
                    <div>
                      <h4 className="text-sm text-gray-400">
                        Enhancing skills which will render in career growth
                      </h4>
                    </div>
                  </div>
                </li>
                </ul>
              <a
                href="https://app.jobacademy.ai/tutor/tutorintroduction"
                className="github-star mt-4 inline-block text-white font-500 px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-gray-800 active:bg-gray-700 text-md shadow hover:shadow-lg"
              >
                Start teaching now
              </a>
            </div>

            <div className="w-full md:w-5/12 px-4 mr-auto ml-auto lg:pt-24 pt-12 relative">
              <img src={require('assets/img/theme/online-tutor.png')} className="tutor-img -top-29-px left-auto" width="600" />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-100 pt-20 pb-6">
        <div className="container mx-auto">
          <div className="w-full lg:w-11/12 mx-auto md:px-4">
            <div className="flex flex-wrap justify-center text-center mb-12">
              <div className="w-full lg:w-8/12 md:px-4">
                <h2 className="text-4xl font-semibold">Here are our Learners</h2>
              </div>
            </div>
            <Slider {...testimonialSettings}>
              {testimonialArr.map((tval, tkey) => (
                <div key={`testimonial-${tkey}`} className="w-full md:w-6/12 lg:w-4/12 mb-12 px-4">
                  <div className="p-5 bg-white w-full shadow rounded-lg h-250">
                    <img
                      alt="..."
                      src={require(`assets/img/students/${tval.image}`)}
                      className="shadow-lg rounded-full mx-auto"
                      width="80"
                    />
                    <div className="pt-2 text-center">
                      <h5 className="text-lg font-semibold">{tval.name}</h5>
                      <div className="mt-6">
                        <p className="text-md text-gray-600 mt-4">
                        <i class="fas fa-quote-left text-gray-400 absolute mb-3"></i>
                        <span className="pl-4 ml-2 mr-2">{tval.review}</span>
                        <i class="fas fa-quote-right text-gray-400 absolute mt-3"></i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
      <section className="bg-white">
        <div className="container mx-auto">
          <div className="flex flex-wrap justify-center py-16 px-4 md:px-12">
            <div className="w-full text-center lg:w-11/12">
              <h3 className="font-semibold text-3xl pb-6">
                Our hired companies or hiring partners
              </h3>
              <div className="sm:block flex flex-col justify-between mt-10">
                <div className="company-section flex justify-center item-center shadow rounded-lg p-4">
                  <img src={require('assets/img/company/arise-global.png')} />
                </div>
                <div className="company-section flex justify-center item-center shadow rounded-lg p-4">
                  <img src={require('assets/img/company/capricorn.png')} />
                </div>
                <div className="company-section flex justify-center item-center shadow rounded-lg p-4">
                  <img src={require('assets/img/company/ibaseit.png')} />
                </div>
                <div className="company-section flex justify-center item-center shadow rounded-lg p-4">
                  <img src={require('assets/img/company/ilander.png')} />
                </div>
                <div className="company-section flex justify-center item-center shadow rounded-lg p-4">
                  <img src={require('assets/img/company/tcs.png')} />
                </div>
                <div className="company-section flex justify-center item-center shadow rounded-lg p-4">
                  <img src={require('assets/img/company/wallero.png')} />
                </div>
                <div className="company-section flex justify-center item-center shadow rounded-lg p-4">
                  <img src={require('assets/img/company/ibm.png')} />
                </div>
                <div className="company-section flex justify-center item-center shadow rounded-lg p-4">
                  <img src={require('assets/img/company/fissionlabs.png')} />
                </div>
                <div className="company-section flex justify-center item-center shadow rounded-lg p-4">
                  <img src={require('assets/img/company/virtusa.png')} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
