import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Grid } from '@material-ui/core';
import basepath from 'basepath';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Loader from 'Loader';
import ComingSoon from 'ComingSoon';

class JobGoals extends Component {
   constructor(props){
      super(props);
     this.state = {
        LatestGoals: undefined,
        contentLoader: false,
     };
   }
   componentDidMount() {
      const _this = this;
      axios.get(`${basepath}/goalslist`)
     .then(function (response) {
       _this.setState({ LatestGoals: response.data.result, contentLoader: true });
     })
     .catch(function (error) {
       console.log(error);
     });
   }
   
  render() {
    const { contentLoader, LatestGoals } = this.state;
    return !contentLoader ? (
      <Loader />
    ) : (
      <div className="w-full h-full flex bg-gray-100">
        <Helmet >
          <title>Jobacademy - Job ready platform</title>
          <meta
            name="description"
            content="Jobacademy Job Ready platform helps job seekers achieve their job goal by connecting them with real time experienced professionals (Job Gurus) for mentorship, learning and skill verification."
          />
          <meta property="og:title" content="Jobacademy - Job ready platform" />
          <meta property="og:url" content="https://jobacademy.ai" />
          <meta property="og:image" itemprop="image" content="https://jobacademy.ai/jobacademy-social.png" />
        </Helmet>
        {LatestGoals !== undefined && LatestGoals.length > 0 ? (
         <Grid container className="p-4 w-full">
          {LatestGoals.map((value, key) => (
            <Grid key={`goal-${key}`} item md={3}  xs={12} className="sm:flex flex-col p-2">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full shadow rounded-lg">
                <blockquote className="relative">
                  <div className="video-card-layer">
                    <div className="video-card-layer h-80 overflow-hidden">
                      <img alt="" src={`${basepath}/uploads/videothumbnail/360/${value.videoThumbnail}`} />
                    </div>
                  </div>
                  <h4 className="text-base font-semibold p-4">{value.goal}</h4>
                  <div className="w-full flex">
                    <div
                      onClick={() => this.props.history.push({
                        pathname: `/job-goals/${value.goal}`,
                        state: {
                          Goals: value,
                        },
                      })}
                      className="w-6/12 p-4 flex justify-start cursor-pointer text-sm font-semibold text-blue-400 hover:text-blue-600"
                    >
                      View details
                    </div>
                    <div
                      onClick = {() => {
                        window.location = `https://app.jobacademy.ai/goalprofile/${value._id}`;
                      }
                    }
                      className="w-6/12 p-4 flex justify-end cursor-pointer text-sm font-semibold text-blue-400 hover:text-blue-600"
                    >
                      Set this goal
                    </div>
                  </div>
                </blockquote>
              </div>
          </Grid>
          ))}
          </Grid>
        ) : (
          <ComingSoon />
        )
  }
          </div>
    );
  }
}

export default withRouter(JobGoals);
