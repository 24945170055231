import React from 'react';
import { Helmet} from 'react-helmet';
export default function BasicTextFields() {
  return (
      <div className="w-full flex justify-center items-center h-500-px">
        <Helmet >
          <title>Jobacademy - Job ready platform</title>
          <meta
            name="description"
            content="Jobacademy Job Ready platform helps job seekers achieve their job goal by connecting them with real time experienced professionals (Job Gurus) for mentorship, learning and skill verification."
          />
          <meta property="og:title" content="Jobacademy - Job ready platform" />
          <meta property="og:url" content="https://jobacademy.ai" />
          <meta property="og:image" itemprop="image" content="https://jobacademy.ai/jobacademy-social.png" />
        </Helmet>
        <div className="w-full text-center">
          <div className="text-3xl uppercase">Something awesome is in the works</div>
          <p className="text-sm">We are working on a new and exciting product that we think you'll really like!</p>
        </div>
        </div>
  );
}
