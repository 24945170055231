import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { TextField, Grid, Button, CircularProgress } from '@material-ui/core';
// import { LocationOnOutlined, CallOutlined, EmailOutlined } from '@material-ui/icons';
import {Helmet} from "react-helmet"; 

class ContactUs extends Component {
  state = {
    name:"",
    email: "",
    mobile:'',
    message: '',
    nameError: false,
    emailError: false,
    mobileError: false,
    messageError: false,
    successMsg: false,
    loading: false
  }

  handleChange = event => {
    const { name, value } = event.target;
    if (value === '') {
      this.setState({
        [`${name}Error`]: true,
      });
    } else {
      this.setState({
        [`${name}Error`]: false,
      });
    }
    this.setState({
        [name]: value,
    });
  }
  handleSubmit = () => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const pattern = /^\d{10}$/;
    if (this.state.name === '') {
      this.setState({
        nameError: true,
      });
    } else if (this.state.mobile === '' || !pattern.test(this.state.mobile)) {
      this.setState({
        mobileError: true,
      });
    } else if (this.state.email === '' || !re.test(this.state.email)) {
      this.setState({
        emailError: true,
      });
    } else if (this.state.message === '') {
      this.setState({
        messageError: true,
      });
    } else {
      this.setState({ loading: true });
      fetch('https://jobacademy.ai/contactenquiry', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify(this.state),
      }).then(resp => resp.json()) // Transform the data into json
        .then(() => {
          setTimeout(() => { this.setState({ successMsg: false }); }, 10000);
          this.setState({
            successMsg: true,
            loading: false,
            name: '',
            email: '',
            mobile: '',
            message: '',
          });
      });
    }
  }
  render() {
    const { name, email, mobile, message, nameError, emailError, mobileError, messageError, successMsg, loading } = this.state;
    return (
      <div>
        <Helmet >
          <title>Jobacademy - Job ready platform</title>
          <meta
            name="description"
            content="Jobacademy Job Ready platform helps job seekers achieve their job goal by connecting them with real time experienced professionals (Job Gurus) for mentorship, learning and skill verification."
          />
          <meta property="og:title" content="Jobacademy - Job ready platform" />
          <meta property="og:url" content="https://jobacademy.ai" />
          <meta property="og:image" itemprop="image" content="https://jobacademy.ai/jobacademy-social.png" />
        </Helmet>
        <Grid container className="lg:m-12">
          <h3 className="w-full flex justify-center text-2xl font-semibold py-4">Contact Us</h3>
          <Grid md={6} xs={12} item>
            <Grid container>
              <Grid md={6} xs={12} item className="contact p-4">
                <div class="info-box">
                  <i class="fas fa-envelope"></i>
                  <h3>Email Us</h3>
                  <p>support@jobacademy.ai</p>
                </div>
              </Grid>
              <Grid md={6} xs={12} item className="contact p-4">
                <div class="info-box">
                  <i class="fas fa-phone-alt"></i>
                  <h3>Call Us</h3>
                  <p>+91 9014121672</p>
                </div>
              </Grid>
              
              <Grid md={6} xs={12} item className="contact p-4">
                <div class="info-box">
                  <i class="fas fa-map-marker-alt"></i>
                  <h3>Address</h3>
                  <p>Plot No. 809, Flat No. 101,<br />Ayyappa Society, Madhapur <br />Hyderabad-500081, Telangana.</p>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid md={6} xs={12} item className="p-4">
            <Grid container className="contact-form">
              <div className="w-full lg:w-6/12 p-2">
                <TextField
                  fullWidth
                  label="Name*"
                  variant="outlined"
                  name="name"
                  autoComplete="off"
                  value={name}
                  onChange={this.handleChange}
                  helperText={nameError ? 'Please enter name.' : ''}
                />
              </div>
              <div className="w-full lg:w-6/12 p-2">
                <TextField
                  fullWidth
                  label="Mobile*"
                  variant="outlined"
                  name="mobile"
                  autoComplete="off"
                  value={mobile}
                  onChange={this.handleChange}
                  helperText={mobileError ? 'Please enter numbers only.' : ''}
                />
              </div>
              <div className="p-2 w-full">
                <TextField
                  fullWidth
                  label="Email*"
                  variant="outlined"
                  name="email"
                  autoComplete="off"
                  value={email}
                  onChange={this.handleChange}
                  helperText={emailError ? 'Please enter valid email.' : ''}
                />
              </div>
              <div className="p-2 w-full">
                <TextField
                  fullWidth
                  rows="4"
                  rowsMax="8"
                  multiline
                  label="Message*"
                  variant="outlined"
                  name="message"
                  value={message}
                  onChange={this.handleChange}
                  helperText={messageError ? 'Please enter text.' : ''}
                />
              </div>
              <div className="m-4 py-3 w-full flex justify-center">
                <Button
                  color="primary" 
                  variant="contained"
                  onClick={this.handleSubmit}
                  disabled={loading}
                  className="w-6/12 contact-btn bg-blue-500"
                >
                  Send Message
                  {loading && (
                    <CircularProgress
                      className="text-white"
                      size={24}
                    />
                  )}
                </Button>
              </div>
              <p className="text-12 text-green">{successMsg ? 'Your message was sent successfully.' : '' }</p>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(ContactUs);
